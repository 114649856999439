<template>
  <div>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="userThirdParts">
      <template slot="adSearch">
        <vm-search :label="$t('common.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
      </template>
      <el-button v-if="editAuth" class="toolbarBtn" slot="toolbar" type="primary" size="mini"
        @click="$refs.editPage.open(0)">
        {{ $t("common.add") }}
      </el-button>
      <el-table-column prop="loginId" :label="$t('userThirdPart.loginId')"></el-table-column>
      <el-table-column prop="userType" :label="$t('userThirdPart.userType')">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.userType === 1">{{ "app" }}</el-tag>
          <el-tag v-else>{{ "web" }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column v-if="editAuth" :label="$t('common.operation')" width="240" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="handleEdit(scope.row)">{{ $t("common.edit") }}</el-button>
          <el-button type="text" class="operateDelBtn" @click="handleDelete(scope.row)">{{ $t("common.delete")
            }}</el-button>
          <el-button :disabled="scope.row.userType === 0" type="text" style="color: #00C291;"
            @click="handleVideo(scope.row)">
            {{ $t("userThirdPart.video") }}
          </el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-dialog ref="editPage" @save-success="getList(1)"></edit-dialog>
  </div>
</template>
<script>
import EditDialog from "./UserThirdPartEdit.vue";

const moduleName = "userThirdParts";

export default {
  components: { EditDialog },
  data() {
    return {
      editAuth: this.$auth(72),
      loading: false,
      search: {
        filter: "",
      },
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    getList(pageNum) {
      this.$refs.vmTable.getList(pageNum);
    },
    handleAdd() {
      this.$refs.editPage.open(0);
    },
    handleEdit(row) {
      this.$refs.editPage.open(row.id);
    },
    handleDelete(row) {
      this.$confirm(this.$t("common.delete") + " " + this.$t("userThirdPart.loginId") + " " + row.loginId + "," + this.$t("common.isDelete") + "?", this.$t("common.tips"), {
        confirmButtonText: this.$t("common.confirm"),
        cancelButtonText: this.$t("common.cancel"),
        type: "warning",
      }).then(() => {
        this.$api.deleteById(moduleName, row.id).then(res => {
          this.getList(1);
          this.$message.success(this.$t("common.tip.deleteSuccess"));
        }).catch(() => {
          this.$message.error(this.$t("common.tip.deleteError"));
        });
      });
    },
    handleVideo(row) {
      this.$bus.$emit("openVideoDialog", row.loginId, row.loginId);
    },
  },
};
</script>
<style lang="scss" scoped></style>
