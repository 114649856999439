<template>
  <el-dialog class="select-dialog" :title="$t('common.select') + ' ' + $l('programCard.programCard', '节目单')"
    :visible.sync="dialogVisible" width="70%" top="2vh" append-to-body>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="programCard">
      <template slot="adSearch">
        <vm-search :label="$t('common.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
      </template>
      <el-table-column prop="cardName" :label="$t('programCard.programCardName')"></el-table-column>
      <el-table-column prop="notice" :label="$t('programCard.notice')"></el-table-column>
      <el-table-column prop="orgName" :label="$t('programCard.companyName')"></el-table-column>
      <el-table-column :label="$t('common.operation')" width="100" align="center">
        <template slot-scope="scope">
          <el-button type="text" style="color: #00C291;" @click="handleSelect(scope.row)">{{ $t("common.select")
            }}</el-button>
        </template>
      </el-table-column>
    </vm-table>
  </el-dialog>
</template>

<script>

const moduleName = "programCard";

export default {
  data() {
    return {
      dialogVisible: false,
      loading: true,
      search: {
        filter: "",
      },
    };
  },
  methods: {
    open() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.getList(1);
      });
    },
    getList(pageNum) {
      this.loading = false;
      this.$refs.vmTable.getList(pageNum);
    },
    handleSelect(row) {
      this.$emit("select", row);
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
