<template>
  <el-dialog
    :title="title"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="50%"
    top="5vh"
    @close="onDialogClose">
    <elevator-lcd-select ref="appSelect" @select="onAppSelect"></elevator-lcd-select>
    <user-select ref="webSelect" @select="onWebSelect"></user-select>
    <div v-loading="contentLoading" class="body">
      <el-form
        ref="formValidate"
        class="form"
        label-width="130px"
        :model="userThirdPart"
        :rules="ruleValidate">
        <el-form-item :label="$t('userThirdPart.userType')">
          <el-radio-group v-model="userThirdPart.userType" :disabled="userThirdPart.id!==0" @change="userTypeChange">
            <el-radio :label="0">web</el-radio>
            <el-radio :label="1">app</el-radio>
          </el-radio-group>
        </el-form-item>
        <div v-if="userThirdPart.userType===0">
          <el-row :gutter="20">
            <el-col :span="15">
              <el-form-item :label="$t('userThirdPart.choose')">
                <el-input :placeholder="$t('common.pleaseSelect')" :value="userThirdPart.username" :disabled="true">
                  <el-button slot="append" icon="el-icon-search" @click="$refs.webSelect.open()"></el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div v-else>
          <el-row :gutter="20">
            <el-col :span="15">
              <el-form-item :label="$t('elevatorLcd.elevatorNo')">
                <el-input :placeholder="$t('common.pleaseSelect')" :value="userThirdPart.elevatorNo" :disabled="true">
                  <el-button slot="append" icon="el-icon-search" @click="$refs.appSelect.open(2)"></el-button>
                </el-input>
              </el-form-item>
              <el-form-item :label="$t('elevatorLcd.elevatorName')">
                <el-input :value="userThirdPart.elevatorName" :disabled="true"></el-input>
              </el-form-item>
              <el-form-item :label="$t('elevatorLcd.useUnitName')">
                <el-input :value="userThirdPart.useUnitName" :disabled="true"></el-input>
              </el-form-item>
              <el-form-item :label="$t('elevatorLcd.lcdId')">
                <el-input :value="userThirdPart.lcdId" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <el-row :gutter="20">
          <el-col :span="15">
            <el-form-item :label="$t('userThirdPart.account')" prop="loginId">
              <el-input :value="userThirdPart.loginId" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item :label="$t('userThirdPart.password')" prop="password">
              <el-input v-model="userThirdPart.password" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{$t("common.cancel")}}</el-button>
      <el-button
        type="primary"
        :loading="submitLoading"
        :disabled="saveDisabled"
        @click="handleSubmit">{{$t("common.save")}}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  import ElevatorLcdSelect from "./ElevatorLcdSelect.vue";
  import UserSelect from "../user/UserSelect.vue";

  const moduleName = "userThirdParts";

  export default {
    components: { UserSelect, ElevatorLcdSelect},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        saveDisabled: false,
        prefix: "",
        userThirdPart: {
          id: 0,
          userType: 0,
          relatedId: "",
          loginId: "",
          password: "",
          username: "",
          elevatorNo: "",
          elevatorName: "",
          useUnitName: "",
          lcdId: "",
        },
        ruleValidate: {
          loginId: [
            {required: true, message: this.$t("userThirdPart.tip.account"), trigger: "change"},
          ],
          password: [
            {required: true, min: 6, max: 20, message: this.$t("userThirdPart.tip.password"), trigger: "blur"},
          ],
        },
      };
    },
    computed: {
      title() {
        return (this.userThirdPart.id === 0 ? this.$t("common.add") : this.$t("common.edit")) + " " + this.$t("route.videoUser");
      },
    },
    methods: {
      open(id) {
        this.dialogVisible = true;
        this.userThirdPart.id = id;
        this.getPrefix();
      },
      getPrefix() {
        this.contentLoading = true;
        this.saveDisabled = true;
        this.$axios.get("configs/userThirdPartLoginIdPrefix").then(res => {
          this.prefix = res.data;
          if (this.userThirdPart.id > 0) {
            this.getData();
          } else {
            this.contentLoading = false;
            this.saveDisabled = false;
          }
        }).catch((error) => {
          this.contentLoading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      getData() {
        this.$api.getById(moduleName, this.userThirdPart.id).then(res => {
          this.userThirdPart = Object.assign(this.userThirdPart, res.data);
          this.contentLoading = false;
          this.saveDisabled = false;
        }).catch((error) => {
          this.contentLoading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      onDialogClose() {
        this.$refs.formValidate.resetFields();
        this.$copy(this.$data, this.$options.data.call(this), "ruleValidate");
      },
      userTypeChange() {
        this.userThirdPart.elevatorNo = "";
        this.userThirdPart.elevatorName = "";
        this.userThirdPart.useUnitName = "";
        this.userThirdPart.lcdId = "";
        this.userThirdPart.relatedId = "";
        this.$refs.formValidate.resetFields();
      },
      onAppSelect(row) {
        this.userThirdPart.elevatorNo = row.elevatorNo;
        this.userThirdPart.elevatorName = row.elevatorName;
        this.userThirdPart.useUnitName = row.useUnitName;
        this.userThirdPart.lcdId = row.lcdId;
        this.userThirdPart.loginId = this.prefix + row.elevatorNo;
        this.userThirdPart.relatedId = row.id;
      },
      onWebSelect(row) {
        this.userThirdPart.relatedId = row.id;
        this.userThirdPart.username = row.username;
        this.userThirdPart.loginId = this.prefix + row.username;
      },
      handleSubmit() {
        this.$refs.formValidate.validate((valid) => {
          if (valid) {
            this.submitLoading = true;
            this.$http.save(moduleName, this.userThirdPart).then(res => {
              this.submitLoading = false;
              this.dialogVisible = false;
              this.$emit("save-success", this.userThirdPart.id);
              this.$message.success(this.$t("common.tip.saveSuccess"));
            }).catch(error => {
              this.submitLoading = false;
              this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
            });
          } else {
            this.$message.error(this.$t("common.tip.submitError") + "!");
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
