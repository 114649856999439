<template>
  <el-tabs v-model="activeName">
    <el-tab-pane v-if="lcdAuth" :label="$t('elevatorLcd.elevatorLcd')" name="first">
      <elevator-lcd-list></elevator-lcd-list>
    </el-tab-pane>
    <el-tab-pane v-if="lcdAuth" :label="$t('elevatorLcd.programList')" name="second">
      <program-card-list></program-card-list>
    </el-tab-pane>
    <el-tab-pane v-if="userThirdAuth" :label="$t('elevatorLcd.videoUser')" name="third">
      <user-third-part-list></user-third-part-list>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import ElevatorLcdList from "@/views/smartScreen/ElevatorLcdList";
import ProgramCardList from "@/views/smartScreen/PogramCardList";
import UserThirdPartList from "@/views/smartScreen/UserThirdPartList";

export default {
  components: { ElevatorLcdList, ProgramCardList, UserThirdPartList },
  data() {
    return {
      lcdAuth: this.$auth(73),
      userThirdAuth: false,
      activeName: "first",
    };
  },
  mounted() {
    if (this.lcdAuth) {
      this.activeName = "first";
      return;
    }
    if (this.userThirdAuth) {
      this.activeName = "third";
      return;
    }
  },
  methods: {},
};
</script>
<style lang="scss" scoped></style>
