<template>
  <el-dialog
    class="select-dialog"
    title="音视频"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="1200px"
    z-index="1000"
    top="5vh"
    :append-to-body="false"
    @close="stop">
    <div
      v-loading="loading"
      element-loading-text="刷新中"
      class="videoObj">
      <div style="width: 480px;height: 640px;display: inline-block;float: left;">
        <div class="font">本人</div>
        <div id="herevideo">
          <span v-if="showIcon" class="iconfont iot-video"></span>
        </div>
      </div>
      <div style="width: 480px;height: 640px;display: inline-block;float: right;">
        <div class="font">用户</div>
        <div id="manualvideo">
          <span v-if="showIcon1" class="iconfont iot-video"></span>
        </div>
      </div>
      <div style="width: 150px;height:75px;clear:both;margin: 0 auto;">
        <div style="float:left;display: inline-block">
          <div class="call" @click="lcdCall">
            <div class="el-icon-phone-outline"></div>
            <div style="position: relative;top: 29px;left: 17px">
              呼叫
            </div>
          </div>
        </div>
        <div style="float:right;display: inline-block">
          <div class="icon" @click="stop">
            <div class="el-icon-close"></div>
            <div style="position: relative;top: 29px;left: 17px">
              挂断
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>

  import auth from "@/util/auth";

  export default {
    data() {
      return {
        loading: false,
        callLoading: false,
        dialogVisible: false,
        showIcon: false,
        showIcon1: false,
        interval: null,
        modal: {},
        appId: "",
        userName: auth.getUsername(),
      };
    },
    methods: {
      open(row) {
        this.dialogVisible = true;
        this.getAppId();
        this.modal = Object.assign(this.modal, row);
        if (!row.roomName) {
          this.$message.error("房间号为空，请呼叫");
        }
        if (row.createRoomTime !== null && parseInt((new Date().getTime() - new Date(row.createRoomTime).getTime()) / 1000 / 60) > 30) {
          this.$message.error("房间等待超时，请重新呼叫");
        }
        this.showIcon = true;
        this.showIcon1 = true;
        if (row.roomName) {
          this.$nextTick(() => {
            this.start(row.roomName);
          });
        }
      },
      lcdCall() {
        let params = {
          elevatorId: this.modal.elevatorId,
        };
        this.$message.success("呼叫中....");
        this.$http.post("elevatorLcds/lcdCall", null, params).then(res => {
          if (res) {
            if (res.data) {
              this.interval = setInterval(() => this.getById(), 5000);
              this.$message.success("呼叫成功");
            } else {
              this.$message.error("呼叫失败");
            }
          }
        }).catch(() => {
          this.$message.error("呼叫失败");
        });
      },
      getById() {
        this.loading = true;
        this.$api.getById("elevatorLcds", this.modal.id).then(res => {
          this.modal = res.data;
          if (this.modal.roomName) {
            this.loading = false;
            this.$nextTick(() => {
              window.BRTC_Stop();
              this.start(this.modal.roomName);
              if (this.interval !== null) {
                clearInterval(this.interval);
              }
            });
          } else {
            this.loading = false;
          }
        }).catch(() => {
          this.loading = false;
        });
      },
      start(roomName) {
        window.BRTC_Start({
          server: "wss://rtc.exp.bcelive.com/janus",
          appid: this.appId,
          // roomname: "stepTestRoom1231",
          roomname: roomName,
          userid: new Date().getTime(),
          displayname: auth.getUsername(),
          videocodec: "h264",//视频编码类型配置
          token: "",
          usingvideo: true,//是否使用本地视频设备
          usingaudio: true,//	是否使用本地音频设备
          rtmpmix: false,//直播转推是否混流
          bitrate: 1500,//	设置摄像头编码的码率
          videoprofile: {"height": 640, "width": 480},//本地摄像头视频配置参数
          showspinner: true,//	是否显示加载过程
          userevent: true,//是否启用用户级事件
          waitpermissiontimeoutms: 3000,//等待权限超时大小，单位是毫秒
          autosubscribe: true,//	是否自动订阅流
          autopublish: true,//是否自动发布流
          showvideobps: true,//是否显示视频的带宽值
          sharescreen: false,//是否是屏幕共享
          aspublisher: true,//是否是发布者
          usingdatachannel: true,//是否使用数据通道
          remotevideoviewid: "manualvideo",//远端视频
          localvideoviewid: "herevideo",//本地视频

          error: (err) => {
            console.log("err:" + err);
          },
          localvideopublishing: () => {
            this.showIcon = false;
            console.log("本地视频流发布成功");
          },
          onlocalstream_end: (name) => {
            this.showIcon = true;
            console.log("本地视频流关闭," + name);
          },
          remotevideoon: (idx) => {
            this.showIcon1 = false;
            console.log("远端视频流到达,remotevideoon, index:" + idx);
          },
          remotevideooff: (idx) => {
            this.showIcon1 = true;
            console.log("远端视频流离开,remotevideooff, index:" + idx);
          },
          userevent_leavingroom: (id, display) => {
            this.$message.success("用户：" + display + "，离开房间！");
          },
          userevent_joinedroom: (id, display, attribute) => {
            this.$message.success("用户：" + display + "，加入房间！");
          },
          onattribute: (e) => {

          },
        });
      },
      getAppId() {
        this.$http.get("sysConfig/webRtcKey").then(res => {
          this.appId = res.data.paramKeyValue;
        }).catch((error) => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
          }
        });
      },
      stop() {
        this.dialogVisible = false;
        window.BRTC_Stop();
        if (this.interval !== null) {
          clearInterval(this.interval);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
.hide {
  display: none !important;
}

.rtc-font-color {
  font-size: 12px;
  color: #4D66FE;
}

.rtc-title-font-color {
  font-size: 20px;
  color: #1A1C47;
}

.videoObj {
  width: 100%;
  height: 720px;
  background-color: #FBFBFB;

  #manualvideo {
    width: 480px;
    height: 600px;
    background-color: #FBFBFB;
    border: 1px solid #E1E1E1;
  }

  #herevideo {
    width: 480px;
    height: 600px;
    background-color: #FBFBFB;
    border: 1px solid #E1E1E1;
  }

  .iconfont {
    font-size: 100px;
    color: #999;
    width: 100px;
    position: relative;
    top: 200px;
    left: 200px;
  }

  .font {
    text-align: center;
    font-size: 20px;
    line-height: 30px;
  }

  .call {
    clear: both;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background-color: #fa5151;
    display: inline-block;

    &:hover {
      background-color: #ee4d4d;
    }
  }


  .icon {
    clear: both;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background-color: #fa5151;
    display: inline-block;

    &:hover {
      background-color: #ee4d4d;
    }
  }

  .close-video {
    clear: both;
    margin: 0 auto;
  }

  .el-icon-close {
    color: #FFFFFF;
    font-size: 33px;
    position: relative;
    top: 15px;
    left: 12px;
  }

  .el-icon-phone-outline {
    color: #FFFFFF;
    font-size: 33px;
    position: relative;
    top: 15px;
    left: 12px;
  }

}
</style>
