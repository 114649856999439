<template>
  <el-dialog v-el-drag-dialog class="small-padding" :title="$t('elevatorLcd.previewProgram')"
    :visible.sync="dialogVisible" top="10px" :width="getDialogWidth()" @close="onDialogClose">
    <div class="preview" :style="{ width: width + 'vh', height: height + 'vh' }">
      <div style="height: 100%;overflow: hidden;margin: 0 auto">
        <el-image v-if="currentType === 'pic'" style="height: 100%;width: 100%" :src="currentPath"
          fit="cover"></el-image>
        <video v-else-if="currentType === 'video'" id="video" autoplay :loop="programCard.programList.length === 1"
          :src="currentPath" width="100%" height="100%" @ended="onEnd()">
          {{ $t('programCard.notSupportVideo') }}
        </video>
        <marquee v-if="programCard.notice" class="notice" behavior="scroll">{{ programCard.notice }}</marquee>
      </div>
    </div>
  </el-dialog>
</template>

<script>
const size = [48, 70];
export default {
  data() {
    return {
      dialogVisible: false,
      programCard: {},
      currentIndex: 0,
      currentPath: "",
      currentType: "",
      width: 48,
      height: 70,
      timer: 0,
    };
  },
  mounted() {
  },
  methods: {
    open(id) {
      let programCardId = id;
      this.dialogVisible = true;
      this.$http.get("programCard/" + programCardId).then(res => {
        this.programCard = res.data;
        this.dealShow();
      });
    },
    dealShow() {
      let programList = this.programCard.programList;
      console.log(programList);
      if (programList && programList.length > this.currentIndex) {
        let current = programList[this.currentIndex];
        this.currentPath = current.attachmentPath;
        if (current.attachmentType === "video") {
          this.currentType = "video";
        } else {
          this.currentType = "pic";
          this.setTimeout();
        }
      }
    },
    // 设置定时器
    setTimeout() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.onEnd();
      }, 10000);
    },
    onEnd() {
      if (this.programCard.programList) {
        this.currentIndex = this.currentIndex + 1;
        if (this.currentIndex === this.programCard.programList.length) {
          this.currentIndex = 0;
        }
        this.dealShow();
      }
    },
    onDialogClose() {
      clearTimeout(this.timer);
      this.$reset();
    },
    getDialogWidth() {
      let showWidth = (this.width / 100) * window.innerHeight;
      return (showWidth + 110) + "px";
    },
  },
};
</script>

<style lang="scss" scoped>
.preview {
  background: url("../../../public/img/preview.jpg") no-repeat #eee;
  background-size: 100% 100%;
  width: 450px;
  height: 600px;
  border: 20px solid #444;
  border-radius: 10px;
  position: relative;
}

.notice {
  padding: 5px 0;
  font-size: 25px;
  background-color: rgba(0, 0, 0, 0.6);
  color: red;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}
</style>
