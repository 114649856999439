<template>
  <el-dialog :title="title" :close-on-click-modal="false" :visible.sync="dialogVisible" width="50%" top="5vh"
    @close="onDialogClose">
    <div v-loading="contentLoading" class="body">
      <el-form ref="formValidate" class="form" :label-width="$l('160px', '80px')" :model="elevatorLcd">
        <el-form-item :label="$t('elevatorLcd.terminalID')" prop="terminalID" :rules="$rule.notNull">
          <el-input v-model="elevatorLcd.terminalID" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('elevatorLcd.controlSystem')" prop="controlSystem">
          <el-input v-model="elevatorLcd.controlSystem" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('elevatorLcd.elevatorNo')" prop="elevatorId">
          <el-input :placeholder="$t('common.pleaseSelect')" :value="elevatorLcd.elevatorNo" readonly>
            <el-button slot="append" icon="el-icon-search" @click="$refs.selectElevator.open('')"></el-button>
          </el-input>
        </el-form-item>
        <elevator-select ref="selectElevator" @select="onElevatorSelect"></elevator-select>
        <el-form-item :label="$t('elevatorLcd.elevatorName')">
          <el-input :value="elevatorLcd.elevatorName" disabled></el-input>
        </el-form-item>
        <el-form-item :label="$t('elevatorLcd.useUnitName')">
          <el-input :value="elevatorLcd.useUnitName" disabled></el-input>
        </el-form-item>
        <el-form-item v-if="elevatorLcd.version && elevatorLcd.version === '2.0' || elevatorLcd.id === 0"
          :label="$t('programCard.programCard')" prop="programCardId">
          <el-input :placeholder="$t('common.pleaseSelect')" :value="elevatorLcd.cardName" readonly>
            <el-button slot="append" icon="el-icon-search" @click="$refs.programCardSelect.open()"></el-button>
          </el-input>
        </el-form-item>
        <program-card-select ref="programCardSelect" @select="onProgramCardSelect"></program-card-select>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{ $t("common.cancel") }}</el-button>
      <el-button type="primary" :loading="submitLoading" :disabled="saveDisabled" @click="handleSubmit">
        {{ $t("common.save") }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import ElevatorSelect from "../elevator/ElevatorSelect.vue";
import ProgramCardSelect from "@/views/smartScreen/ProgramCardSelect";

const moduleName = "elevatorLcds";

export default {
  components: { ElevatorSelect, ProgramCardSelect },
  data() {
    return {
      dialogVisible: false,
      contentLoading: false,
      submitLoading: false,
      saveDisabled: false,
      isReplace: false,
      oldElevatorId: 0,
      elevatorLcd: {
        id: 0,
        version: "",
        elevatorId: 0,
        terminalID: "",
        controlSystem: "",
        programCardId: "",
        cardName: "",
        elevatorNo: "",
        elevatorName: "",
        useUnitName: "",
        elevatorRegisterNo: "",
        elevatorDtuCode: "",
      },
    };
  },
  computed: {
    title() {
      return (this.elevatorLcd.id === 0 ? this.$t("common.add") : this.$t("common.edit")) + " " + this.$t("elevatorLcd.elevatorLcd");
    },
  },
  methods: {
    open(id) {
      this.dialogVisible = true;
      this.elevatorLcd.id = id;
      if (this.elevatorLcd.id > 0) {
        this.getData();
      }
    },
    getData() {
      this.contentLoading = true;
      this.saveDisabled = true;
      this.$api.getById(moduleName, this.elevatorLcd.id).then(res => {
        this.elevatorLcd = res.data;
        this.oldElevatorId = res.data.elevatorId;
        this.contentLoading = false;
        this.saveDisabled = false;
      }).catch((error) => {
        this.contentLoading = false;
        if (error.response) {
          this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
        }
      });
    },
    onDialogClose() {
      this.$refs.formValidate.resetFields();
      this.$copy(this.$data, this.$options.data.call(this), "ruleValidate");
    },
    onElevatorSelect(row) {
      this.elevatorLcd.elevatorId = row.id;
      this.elevatorLcd.elevatorNo = row.no;
      this.elevatorLcd.elevatorName = row.name;
      this.elevatorLcd.useUnitName = row.useUnitName;
    },
    onProgramCardSelect(row) {
      console.log("节目单");
      console.log(row);
      this.elevatorLcd.programCardId = row.id;
      this.elevatorLcd.cardName = row.cardName;
    },
    saveElevatorLcd() {
      this.submitLoading = true;
      this.$http.save(moduleName, this.elevatorLcd).then(res => {
        this.submitLoading = false;
        this.dialogVisible = false;
        this.$emit("save-success", this.elevatorLcd.id === 0);
        this.$message.success(this.$t("common.tip.saveSuccess"));
      }).catch(error => {
        this.submitLoading = false;
        console.log(error.response.data.message);
        if (error.response.data.exception === "org.springframework.dao.DuplicateKeyException") {
          this.$message.error(this.$t("elevatorLcd.tip.lcdId") + "!");
        } else {
          this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
        }
      });
    },
    handleSubmit() {
      this.$refs.formValidate.validate((valid) => {
        if (valid) {
          if (this.elevatorLcd.elevatorId !== this.oldElevatorId) {
            this.$http.get("elevatorLcds/replace/" + this.elevatorLcd.elevatorId).then(res => {
              if (res.data) {
                this.$confirm(this.$t("elevatorLcd.replace"), this.$t("common.tips"), {
                  confirmButtonText: this.$t("common.confirm"),
                  cancelButtonText: this.$t("common.cancel"),
                  type: "warning",
                  distinguishCancelAndClose: true,
                }).then(() => {
                  this.saveElevatorLcd();
                }).catch(e => {
                  if (e === "cancel") {
                    this.elevatorLcd.elevatorId = 0;
                    this.elevatorLcd.elevatorName = "";
                    this.elevatorLcd.elevatorNo = "";
                    this.elevatorLcd.useUnitName = "";
                  }
                });
              } else {
                this.saveElevatorLcd();
              }
            });
          } else {
            this.saveElevatorLcd();
          }
        } else {
          this.$message.error(this.$t("common.tip.submitError") + "!");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
